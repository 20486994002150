import get from 'lodash/get';
import {
  applicantTypes,
  autopayRelationshipType,
  employerFieldNaList,
  residenceTypes,
  NOT_APPLICABLE
} from '../../values/application';

export const rateGeniusApplication = (state, getters, rootState, rootGetters) => {
  const primaryApplicantCurrentAddress = rootGetters['residence/primaryApplicantCurrentAddress'];
  const hasJointApplicant = rootGetters['applicant/hasJointApplicant'];

  let payload = {
    mailCode: rootGetters['mailCodeOffer/mailCode'],
    utmstring: rootGetters['trackingData/utmString'],
    APP_TYPE: hasJointApplicant ? applicantTypes.JOINT : applicantTypes.SINGLE,
    FIRST_NAME: rootGetters['applicant/primaryApplicantFirstName'],
    LAST_NAME: rootGetters['applicant/primaryApplicantLastName'],
    DOB: rootGetters['applicant/primaryApplicantDateOfBirth'],
    SSN: rootGetters['applicant/primaryApplicantSocialSecurityNumber'],
    EMAIL: rootGetters['applicant/primaryApplicantEmailAddress'],
    PHONE: rootGetters['applicant/primaryApplicantMobileNumber'] || NOT_APPLICABLE,
    RES_TYPE: rootGetters['residence/primaryApplicantCurrentResidenceType'] || NOT_APPLICABLE,
    ADDRESS: get(primaryApplicantCurrentAddress, 'street', NOT_APPLICABLE),
    CITY: get(primaryApplicantCurrentAddress, 'city', NOT_APPLICABLE),
    STATE: get(primaryApplicantCurrentAddress, 'state', NOT_APPLICABLE),
    ZIP: get(primaryApplicantCurrentAddress, 'zipCode', NOT_APPLICABLE),
    ADDR_Y: rootGetters['residence/primaryApplicantNumberOfYearsAtCurrentResidence'],
    ADDR_M: rootGetters['residence/primaryApplicantNumberOfMonthsAtCurrentResidence'],
    HOUSE_PMT: rootGetters['residence/primaryApplicantMonthlyPaymentAtCurrentResidenceAsNumber'] || NOT_APPLICABLE,
    EMP_PHONE: rootGetters['applicant/primaryApplicantMobileNumber'] || NOT_APPLICABLE,
    EMP_TYPE: rootGetters['employment/primaryApplicantCurrentEmploymentTypeValue'] || NOT_APPLICABLE,
    EMPLOYER: rootGetters['employment/primaryApplicantCurrentEmploymentEmployerName'] || NOT_APPLICABLE,
    EMP_TITLE: rootGetters['employment/primaryApplicantCurrentEmploymentJobTitle'] || NOT_APPLICABLE,
    EMP_Y: rootGetters['employment/primaryApplicantNumberOfYearsAtCurrentEmployment'],
    EMP_M: rootGetters['employment/primaryApplicantNumberOfMonthsAtCurrentEmployment'],
    GROSS_MTH_INC: rootGetters['employment/primaryApplicantMonthlyIncomeAtCurrentEmploymentAsNumber'] || NOT_APPLICABLE,
    OTHER_MTH_INC: rootGetters['employment/primaryApplicantAdditionalIncomeAmountPerMonthAsNumber'],
    MAKE: rootGetters['vehicle/makeName'],
    MODEL: rootGetters['vehicle/modelName'],
    YEAR: rootGetters['vehicle/year'],
    MILEAGE: rootGetters['vehicle/mileageAsNumber'],
    BODY_STYLE: rootGetters['vehicle/trimName'],
    INTENT: rootGetters['global/reasonForLoanChange'],
    SOURCE_ID: rootGetters['trackingData/sponsorId'],
    PROMO_CODE: rootGetters['promoCode/getPromoCode']
  };

  if (rootGetters['residence/primaryApplicantHasResidedAtCurrentResidenceLessThanTwoYears']) {
    const primaryApplicantPreviousAddress = rootGetters['residence/primaryApplicantPreviousAddress'];

    payload = {
      ...payload,
      PREV_ADDRESS: get(primaryApplicantPreviousAddress, 'street'),
      PREV_CITY: get(primaryApplicantPreviousAddress, 'city'),
      PREV_STATE: get(primaryApplicantPreviousAddress, 'state'),
      PREV_ZIP: get(primaryApplicantPreviousAddress, 'zipCode'),
      PREV_ADDR_Y: rootGetters['residence/primaryApplicantNumberOfYearsAtPreviousResidence'],
      PREV_ADDR_M: rootGetters['residence/primaryApplicantNumberOfMonthsAtPreviousResidence']
    };
  }

  if (rootGetters['employment/primaryApplicantHasPreviousEmploymentType']) {
    const notApplicable = employerFieldNaList.includes(
      rootGetters['employment/primaryApplicantPreviousEmploymentTypeValue']
    );

    payload = {
      ...payload,
      PREV_EMP_TYPE: rootGetters['employment/primaryApplicantPreviousEmploymentTypeValue'],
      PREV_EMPLOYER: notApplicable
        ? NOT_APPLICABLE
        : rootGetters['employment/primaryApplicantPreviousEmploymentEmployerName'],
      PREV_EMP_TITLE: notApplicable
        ? NOT_APPLICABLE
        : rootGetters['employment/primaryApplicantPreviousEmploymentJobTitle'],
      PREV_EMP_Y: rootGetters['employment/primaryApplicantNumberOfYearsAtPreviousEmployment'],
      PREV_EMP_M: rootGetters['employment/primaryApplicantNumberOfMonthsAtPreviousEmployment']
    };
  }

  if (hasJointApplicant) {
    const jointApplicantCurrentAddress = rootGetters['residence/jointApplicantCurrentAddress'];

    payload = {
      ...payload,
      JT_SSN: rootGetters['applicant/jointApplicantSocialSecurityNumber'] || NOT_APPLICABLE,
      JT_REL: autopayRelationshipType[rootGetters['applicant/jointApplicantRelationshipToPrimaryApplicantTypeValue']],
      JT_FIRST_NAME: rootGetters['applicant/jointApplicantFirstName'] || NOT_APPLICABLE,
      JT_LAST_NAME: rootGetters['applicant/jointApplicantLastName'] || NOT_APPLICABLE,
      JT_DOB: rootGetters['applicant/jointApplicantDateOfBirth'] || NOT_APPLICABLE,
      JT_PHONE: rootGetters['applicant/jointApplicantMobileNumber'] || NOT_APPLICABLE,
      JT_EMP_TYPE: rootGetters['employment/jointApplicantCurrentEmploymentTypeValue'],
      JT_EMP_PHONE: rootGetters['applicant/jointApplicantMobileNumber'] || NOT_APPLICABLE,
      JT_RES_TYPE: residenceTypes.OTHER,
      JT_HOUSE_PMT: rootGetters['residence/jointApplicantMonthlyPaymentAtCurrentResidenceAsNumber'] || NOT_APPLICABLE,
      JT_EMPLOYER: rootGetters['employment/jointApplicantCurrentEmploymentEmployerName'] || NOT_APPLICABLE,
      JT_EMP_TITLE: rootGetters['employment/jointApplicantCurrentEmploymentJobTitle'] || NOT_APPLICABLE,
      JT_EMP_Y: rootGetters['employment/jointApplicantNumberOfYearsAtCurrentEmployment'],
      JT_EMP_M: rootGetters['employment/jointApplicantNumberOfMonthsAtCurrentEmployment'],
      JT_OTHER_MTH_INC: rootGetters['employment/jointApplicantAdditionalIncomeAmountPerMonthAsNumber'],
      JT_ADDR_Y: rootGetters['residence/jointApplicantNumberOfYearsAtCurrentResidence'],
      JT_ADDR_M: rootGetters['residence/jointApplicantNumberOfMonthsAtCurrentResidence'],
      JT_GROSS_MTH_INC: rootGetters['employment/jointApplicantMonthlyIncomeAtCurrentEmploymentAsNumber'] || NOT_APPLICABLE,
      JT_ADDRESS: get(jointApplicantCurrentAddress, 'street', NOT_APPLICABLE),
      JT_APT: get(jointApplicantCurrentAddress, 'street2', NOT_APPLICABLE),
      JT_CITY: get(jointApplicantCurrentAddress, 'city', NOT_APPLICABLE),
      JT_STATE: get(jointApplicantCurrentAddress, 'state', NOT_APPLICABLE),
      JT_ZIP: get(jointApplicantCurrentAddress, 'zipCode', NOT_APPLICABLE)
    };
  }

  // Joint previous residence
  if (rootGetters['residence/jointApplicantHasResidedAtCurrentResidenceLessThanTwoYears']) {
    const jointApplicantPreviousAddress = rootGetters['residence/jointApplicantPreviousAddress'];

    payload = {
      ...payload,
      JT_PREV_ADDRESS: get(jointApplicantPreviousAddress, 'street'),
      JT_PREV_CITY: get(jointApplicantPreviousAddress, 'city'),
      JT_PREV_STATE: get(jointApplicantPreviousAddress, 'state'),
      JT_PREV_ZIP: get(jointApplicantPreviousAddress, 'zipCode')
    };
  }

  // joint previous employment
  if (rootGetters['employment/jointApplicantHasPreviousEmploymentType']) {
    const notApplicable = employerFieldNaList.includes(
      rootGetters['employment/jointApplicantPreviousEmploymentTypeValue']
    );
    payload = {
      ...payload,
      JT_PREV_EMP_TYPE: rootGetters['employment/jointApplicantPreviousEmploymentTypeValue'],
      JT_PREV_EMPLOYER: notApplicable
        ? NOT_APPLICABLE
        : rootGetters['employment/jointApplicantPreviousEmploymentEmployerName'],
      JT_PREV_EMP_TITLE: notApplicable
        ? NOT_APPLICABLE
        : rootGetters['employment/jointApplicantPreviousEmploymentJobTitle'],
      JT_PREV_EMP_Y: rootGetters['employment/jointApplicantNumberOfYearsAtPreviousEmployment'],
      JT_PREV_EMP_M: rootGetters['employment/jointApplicantNumberOfMonthsAtPreviousEmployment']
    };
  }

  return payload;
};
