import { StoreModifier } from '../../utilities/storeModifiers';
import { RG_DIRECT_MAIL } from '../../values/sponsor';

const state = {
  promo_code: null,
  drop_date: null,
  personal_data: {
    first_name: null,
    middle_name: null,
    last_name: null,
    street_address: null,
    apartment: null,
    city: null,
    state: null,
    zip_code: null,
    county_code: null,
    age: null,
    credit_score: null
  },
  original_loan: {
    interest_rate: null,
    loan_term: null,
    months_open: null,
    remaining_term: null,
    monthly_payment: null,
    current_balance: null
  },
  offer_data: {
    lender_name: null,
    lender_id: null,
    interest_rate: null,
    loan_term: null,
    only_right_of_refusal: null
  }
};

const getters = {
  getPromoCode: state => state.promo_code,
  getDropDate: state => state.drop_date,
  getPersonalData: state => state.personal_data,
  hasPromoData: state => state.personal_data.first_name !== null && state.promo_code !== null,
  getFirstName: state => state.personal_data.first_name,
  getOriginalLoan: state => state.original_loan,
  getOfferData: state => state.offer_data
};

const mutations = {
  SET_PROMO_CODE (state, promoCode) {
    state.promo_code = promoCode;
  },

  SET_DROP_DATE (state, dropDate) {
    state.drop_date = dropDate;
  },

  SET_PERSONAL_DATA (state, personalData) {
    state.personal_data = { ...state.personal_data, ...personalData };
  },

  SET_ORIGINAL_LOAN (state, originalLoan) {
    state.original_loan = { ...state.original_loan, ...originalLoan };
  },

  SET_OFFER_DATA (state, offerData) {
    state.offer_data = { ...state.offer_data, ...offerData };
  }
};

const actions = {
  setPromoData ({ commit }, data) {
    commit('SET_PROMO_CODE', data.promo_code);
    commit('SET_DROP_DATE', data.drop_date);
    commit('SET_PERSONAL_DATA', data.personal_data);
    commit('SET_ORIGINAL_LOAN', data.original_loan);
    commit('SET_OFFER_DATA', data.offer_data);
    commit('trackingData/SET_LENDER_ID', data.offer_data.lender_id, { root: true });
    commit('trackingData/SET_SPONSOR_ID', RG_DIRECT_MAIL, { root: true });

    commit(
      'residence/SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_CITY',
      data.personal_data.city,
      { root: true }
    );

    commit(
      'residence/SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STATE',
      data.personal_data.state,
      { root: true }
    );

    commit(
      'residence/SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STREET',
      data.personal_data.street_address,
      { root: true }
    );

    commit(
      'residence/SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_STREET_TWO',
      data.personal_data.apartment,
      { root: true }
    );

    commit(
      'residence/SET_PRIMARY_APPLICANT_CURRENT_ADDRESS_ZIP_CODE',
      data.personal_data.zip_code,
      { root: true }
    );

    commit(
      'applicant/SET_PRIMARY_APPLICANT_FIRST_NAME',
      data.personal_data.first_name,
      { root: true }
    );

    commit(
      'applicant/SET_PRIMARY_APPLICANT_LAST_NAME',
      data.personal_data.last_name,
      { root: true }
    );
  }
};

export default new StoreModifier({
  actions,
  getters,
  mutations,
  namespaced: true,
  state
});
