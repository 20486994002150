import get from 'lodash/get';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import mapKeys from 'lodash/mapKeys';
import set from 'lodash/set';
import { isNonEmptyString } from '../../../utilities/isNonEmptyString';
import { DEFAULT_WORKFLOW } from '../../../values/brand';

export const getters = {
  /**
   * Returns Autopay data from the attribution log
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @returns {Object}
   */
  autopayData: (state, getters, rootState, rootGetters) => {
    const attribution = find(
      state.attributionLog.attributions,
      { leadChannel: rootGetters['leadChannel/leadChannel'] }
    );

    const data = get(attribution, 'autopayData.a', {});

    if (rootGetters['mailCodeOffer/hasMailCode']) {
      data.partnerRecordUniqueIdentifier = rootGetters['mailCodeOffer/mailCode'];
    } else if (isEmpty(data.partnerRecordUniqueIdentifier) && getters.sponsorClickId) {
      data.partnerRecordUniqueIdentifier = getters.sponsorClickId;
    }

    return data;
  },

  /**
   * Log of attribution states.
   * @param state
   * @returns {getters.attributionLog|(function(*))|state.attributionLog|{attributions}|*}
   */
  attributionLog: state => state.attributionLog,

  /**
   * Template id passed in as tid
   * @param state
   * @returns {getters.templateId|(function(*))|null}
   */
  templateId: state => state.templateId,

  /**
   * Gets the referring Sponsor ID
   * @param state {object}
   * @returns {(number|null)}
   */
  sponsorId: state => state.sponsorId,

  /**
   * @param state {object}
   * @returns {(number|null)}
   */
  sponsorClickId: state => state.sponsorClickId,

  /**
   * If the application is Direct Mail, gets the mailing lender ID
   * @param state {object}
   * @returns {(number|null)}
   */
  lenderId: state => state.lenderId,

  /**
   * campaign ID aka cid query param
   * @param state
   * @returns {getters.campaignId|(function(*))|null|*}
   */
  campaignId: state => state.campaignId,

  /**
   * Is the applicant a Direct Mail respondent?
   * @param state {object}
   * @returns {boolean}
   */
  isDirectMail: state => state.lenderId !== null,

  /**
   * Gets the GA/GTM Visitor ID
   * @param state {object}
   * @returns {(number|null)}
   */
  googleVisitorId: state => state.googleVisitorId,

  /**
   * Gets the active Live Chat ID
   * @param state {object}
   * @returns {(number|null)}
   */
  liveChatId: state => state.liveChatId,

  /**
   * Gets the entire parsed UTM object
   * @param state {object}
   * @returns {(Object|{utm_campaign, utm_term, utm_source, utm_medium, utm_content})}
   */
  utms: state => {
    return mapKeys(state.utm, (value, key) => {
      return `utm_${key}`;
    });
  },

  /**
   * Gets a UTM value by its name
   * Usage:
   * `const source = utm('source');`
   * @param state {object}
   * @returns {function(*): {string}}
   */
  utm: state => key => state.utm[key],

  /**
   * Gets a workflow value by name
   * @param state {object}
   * @returns {string}
   */
  workflow: state => state.workflow || DEFAULT_WORKFLOW,

  /**
   * For assembling UTM data in payload
   * @param state
   * @param getters
   * @returns {string}
   */
  utmString: (state, getters) => {
    const workflow = get(getters, 'workflow');

    return JSON.stringify({
      WORKFLOW: isNonEmptyString(workflow)
        ? workflow.toUpperCase()
        : workflow,
      WHICH_FORM: 'FASTTRACK',
      cid: getters.campaignId,
      subid: getters.sponsorClickId,
      tid: getters.templateId,
      visitorid: getters.googleVisitorId,
      ...getters.utms,
      ...getters.attributionLog
    });
  },

  metaData: (state, getters, rootState, rootGetters) => {
    const metaData = {
      schemaVersion: '1.4.0',
      onboardingExperience: 'FASTTRACK',
      googleAnalytics: {
        clientId: get(getters, 'googleVisitorId') || 'no cookie available'
      },
      experience: {
        FASTTRACK: {}
      }
    };

    if (isNonEmptyString(get(getters, 'workflow'))) {
      set(metaData, 'experience.FASTTRACK.workflow', get(getters, 'workflow'));
    }

    const initialUrl = rootGetters['global/originMarketingSourceUrl'];

    if (isNonEmptyString(initialUrl)) {
      set(metaData, 'experience.FASTTRACK.initialUrl', initialUrl);

      try {
        const queryParams = Object.fromEntries(
          new URL(initialUrl).searchParams.entries()
        );

        if (!isEmpty(queryParams)) {
          set(metaData, 'experience.FASTTRACK.queryParams', queryParams);
        }
      } catch {
        // It appears the URL is invalid, or Object.fromEntries threw an error
        // This should not happen frequently, but either way, there's nothing
        // we can do to help so we are not logging the error at this point
      }
    }

    const consumerSelectedRates = [
      rootGetters['mailCodeOffer/consumerSelectedRate'],
      rootGetters['offers/consumerSelectedRate'],
      rootGetters['prequal/consumerSelectedRate']
    ].filter((rate) => isObject(rate) && !isEmpty(rate));

    if (!isEmpty(consumerSelectedRates)) {
      set(metaData, 'consumerSelectedRates', consumerSelectedRates);
    }

    return metaData;
  },

  /**
   * Gets the prequal ID
   *
   * @param state {object}
   * @returns {(number|null)}
   */
  prequalId: state => state.prequalId,

  /**
   * Gets the refer ID
   *
   * @param state {object}
   * @returns {(number|null)}
   */
  referId: state => state.referId
};
