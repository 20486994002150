import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Integrations } from '@sentry/tracing';
import {
  CaptureConsole as CaptureConsoleIntegration,
  ExtraErrorData as ExtraErrorDataIntegration,
  Offline as OfflineIntegration
} from '@sentry/integrations';
import get from 'lodash/get';
import set from 'lodash/set';

import store from '../../store/index';
import { sanitizeRequestBody } from '../../utilities/sanitizeRequestBody';
import { serviceContainer } from '../../di';

// console levels to capture in Sentry
const levels = process.env.APP_ENV === 'prod'
  ? ['error']
  : ['warn', 'error'];

// Adjust this value as needed for production...
const tracesSampleRate = process.env.APP_ENV === 'prod'
  ? 0.4
  : 1.0;

export const initializeSentry = () => Sentry.init({
  Vue,
  autoSessionTracking: process.env.SENTRY_AUTO_SESSION_TRACKING === 'true',
  dsn: process.env.SENTRY_DSN,
  debug: process.env.SENTRY_DEBUG === 'true',
  environment: process.env.APP_ENV,
  release: process.env.CURRENT_VERSION,
  ignoreErrors: [
    'Non-Error promise rejection captured',
    /Navigation cancelled/
  ],
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(serviceContainer.vueRouter),
      tracingOrigins: [
        /.*autopay\.com/g,
        /.*hotjar\.com/g,
        /.*google-analytics\.com/g
      ],
      idleTimeout: 5000
    }),
    new ExtraErrorDataIntegration({ depth: 5 }),
    new CaptureConsoleIntegration({ levels }),
    new OfflineIntegration()
  ],
  logErrors: process.env.APP_ENV !== 'prod',
  tracesSampleRate,

  // list of top-level components used by the router
  trackComponents: [
    'App',
    'BrowserNotSupported',
    'ContactJoint',
    'ContactMain',
    'Details',
    'EmploymentJoint',
    'EmploymentMain',
    'Home',
    'LandingPage',
    'Make',
    'Model',
    'PageNotFound',
    'Pre',
    'Promo',
    'ResidenceJoint',
    'ResidenceMain',
    'Review',
    'Thanks',
    'Trim',
    'UnifiedPage',
    'Year'
  ],

  beforeSend (event) {
    [
      'contexts.Error.config.data',
      'config.data',
      'extra.config.data',
      'extra.request.__sentry_xhr__.body'
    ]
      .filter((eventPath) => get(event, eventPath))
      .forEach((eventPath) => {
        set(event, eventPath, sanitizeRequestBody(get(event, eventPath)));
      });

    // append current feature flag states to Sentry reports
    event.extra = {
      ...event.extra,
      ...store.getters['feature/currentFeatureFlagStates'],
      brand: store.getters['leadChannel/brand'],
      online: store.getters['connection/isOnlineAsString'],
      networkStatus: store.getters['connection/networkStatus']
    };

    return event;
  }
});
