import cloneDeep from 'lodash/cloneDeep';

const employment = {
  employerName: '',
  grossAnnualIncome: null,
  grossIncomeConfirmed: false,
  grossIncomeConfirmedValue: null,
  jobTitle: '',
  numberOfMonthsEmployed: 0,
  numberOfYearsEmployed: 0,
  type: null
};

const additionalIncome = {
  hasAdditionalIncome: false,
  amount: null,
  frequency: null,
  source: null
};

export const state = {
  primary: {
    current: cloneDeep(employment),
    previous: cloneDeep(employment),
    additionalIncome: cloneDeep(additionalIncome)
  },
  joint: {
    current: cloneDeep(employment),
    previous: cloneDeep(employment),
    additionalIncome: cloneDeep(additionalIncome)
  }
};
