export class PromoCodeService {
  /**
   *
   * @param {localStorage} localStorage
   * @param {Api} api
   * @param {StorageService} vuexStoreService
   */
  constructor (localStorage, api, vuexStoreService) {
    this.storageKey = 'promo';
    this.localStorage = localStorage;
    this.api = api;
    this.vuexStoreService = vuexStoreService;
  }

  /**
   * set the promo code in local storage
   *
   * @param promoCode
   * @param zipCode
   */
  setPromoCode (promoCode, zipCode) {
    this.localStorage.set(this.storageKey, {
      promoCode: promoCode,
      zipCode: zipCode
    });
  }

  /**
   * get promo code from localstorage
   *
   * @returns {*}
   */
  getPromoCode () {
    return this.localStorage.get(this.storageKey);
  }

  /**
   * Find Promo code from stored promocode data
   *
   * @returns {Promise<*>}
   */
  async fetchPromoData () {
    const promoCodeDataFromLocalStorage = this.getPromoCode();

    if (promoCodeDataFromLocalStorage) {
      try {
        return await this.api.PromoCode.lookup(
          promoCodeDataFromLocalStorage.promoCode,
          promoCodeDataFromLocalStorage.zipCode
        );
      } catch (err) {
        window.dataLayer.push({
          event: 'promoFailed',
          promoFailed: err.message
        });
      }
    }
  }

  /**
   * Rehydrate the promo code
   *
   * @returns {Promise<void>}
   */
  async hydrateFromLocalStorage () {
    const promoData = await this.fetchPromoData();
    if (promoData) {
      this.vuexStoreService.syncPromoData(promoData);
    }
  }
}
