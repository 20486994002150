export const actions = {
  // primary applicant current employment
  setPrimaryApplicantCurrentEmploymentType ({ commit }, type) {
    commit('SET_PRIMARY_APPLICANT_CURRENT_EMPLOYMENT_TYPE', type);
  },
  setPrimaryApplicantCurrentEmploymentEmployerName ({ commit }, employerName) {
    commit(
      'SET_PRIMARY_APPLICANT_CURRENT_EMPLOYMENT_EMPLOYER_NAME',
      employerName
    );
  },
  setPrimaryApplicantCurrentEmploymentJobTitle ({ commit }, jobTitle) {
    commit('SET_PRIMARY_APPLICANT_CURRENT_EMPLOYMENT_JOB_TITLE', jobTitle);
  },
  setPrimaryApplicantNumberOfYearsAtCurrentEmployment ({ commit }, numberOfYearsEmployed) {
    commit(
      'SET_PRIMARY_APPLICANT_NUMBER_OF_YEARS_AT_CURRENT_EMPLOYMENT',
      numberOfYearsEmployed
    );
  },
  setPrimaryApplicantNumberOfMonthsAtCurrentEmployment ({ commit }, numberOfMonthsEmployed) {
    commit(
      'SET_PRIMARY_APPLICANT_NUMBER_OF_MONTHS_AT_CURRENT_EMPLOYMENT',
      numberOfMonthsEmployed
    );
  },
  setPrimaryApplicantGrossAnnualIncomeAtCurrentEmployment ({ commit }, grossAnnualIncome) {
    commit(
      'SET_PRIMARY_APPLICANT_GROSS_ANNUAL_INCOME_AT_CURRENT_EMPLOYMENT',
      grossAnnualIncome
    );
  },
  setPrimaryApplicantGrossAnnualIncomeConfirmedAtCurrentEmployment ({ commit }, grossAnnualIncomeConfirmed) {
    commit(
      'SET_PRIMARY_APPLICANT_GROSS_ANNUAL_INCOME_CONFIRMED_AT_CURRENT_EMPLOYMENT',
      grossAnnualIncomeConfirmed
    );
  },
  setPrimaryApplicantGrossAnnualIncomeConfirmedValueAtCurrentEmployment ({ commit }, grossAnnualIncomeConfirmedValue) {
    commit(
      'SET_PRIMARY_APPLICANT_GROSS_ANNUAL_INCOME_CONFIRMED_VALUE_AT_CURRENT_EMPLOYMENT',
      grossAnnualIncomeConfirmedValue
    );
  },
  // Additional Income
  setPrimaryApplicantHasAdditionalIncome ({ commit }, hasAdditionalIncome) {
    commit('SET_PRIMARY_APPLICANT_HAS_ADDITIONAL_INCOME', hasAdditionalIncome);
  },
  setPrimaryApplicantAdditionalIncomeAmount ({ commit }, amount) {
    commit('SET_PRIMARY_APPLICANT_ADDITIONAL_INCOME_AMOUNT', amount);
  },
  setPrimaryApplicantAdditionalIncomeFrequency ({ commit }, frequency) {
    commit('SET_PRIMARY_APPLICANT_ADDITIONAL_INCOME_FREQUENCY', frequency);
  },
  setPrimaryApplicantAdditionalIncomeSource ({ commit }, source) {
    commit('SET_PRIMARY_APPLICANT_ADDITIONAL_INCOME_SOURCE', source);
  },
  // primary applicant previous employment
  setPrimaryApplicantPreviousEmploymentEmployerName ({ commit }, employerName) {
    commit(
      'SET_PRIMARY_APPLICANT_PREVIOUS_EMPLOYMENT_EMPLOYER_NAME',
      employerName
    );
  },
  setPrimaryApplicantPreviousEmploymentJobTitle ({ commit }, jobTitle) {
    commit('SET_PRIMARY_APPLICANT_PREVIOUS_EMPLOYMENT_JOB_TITLE', jobTitle);
  },
  setPrimaryApplicantPreviousEmploymentType ({ commit }, type) {
    commit('SET_PRIMARY_APPLICANT_PREVIOUS_EMPLOYMENT_TYPE', type);
  },
  setPrimaryApplicantNumberOfMonthsAtPreviousEmployment ({ commit }, numberOfMonthsEmployed) {
    commit(
      'SET_PRIMARY_APPLICANT_NUMBER_OF_MONTHS_AT_PREVIOUS_EMPLOYMENT',
      numberOfMonthsEmployed
    );
  },
  setPrimaryApplicantNumberOfYearsAtPreviousEmployment ({ commit }, numberOfYearsEmployed) {
    commit(
      'SET_PRIMARY_APPLICANT_NUMBER_OF_YEARS_AT_PREVIOUS_EMPLOYMENT',
      numberOfYearsEmployed
    );
  },

  // joint applicant current employment
  setJointApplicantCurrentEmploymentType ({ commit }, type) {
    commit('SET_JOINT_APPLICANT_CURRENT_EMPLOYMENT_TYPE', type);
  },
  setJointApplicantCurrentEmploymentEmployerName ({ commit }, employerName) {
    commit('SET_JOINT_APPLICANT_CURRENT_EMPLOYMENT_EMPLOYER_NAME', employerName);
  },
  setJointApplicantCurrentEmploymentJobTitle ({ commit }, jobTitle) {
    commit('SET_JOINT_APPLICANT_CURRENT_EMPLOYMENT_JOB_TITLE', jobTitle);
  },
  setJointApplicantNumberOfYearsAtCurrentEmployment ({ commit }, numberOfYearsEmployed) {
    commit(
      'SET_JOINT_APPLICANT_NUMBER_OF_YEARS_AT_CURRENT_EMPLOYMENT',
      numberOfYearsEmployed
    );
  },
  setJointApplicantNumberOfMonthsAtCurrentEmployment ({ commit }, numberOfMonthsEmployed) {
    commit(
      'SET_JOINT_APPLICANT_NUMBER_OF_MONTHS_AT_CURRENT_EMPLOYMENT',
      numberOfMonthsEmployed
    );
  },
  setJointApplicantGrossAnnualIncomeAtCurrentEmployment ({ commit }, grossAnnualIncome) {
    commit(
      'SET_JOINT_APPLICANT_GROSS_ANNUAL_INCOME_AT_CURRENT_EMPLOYMENT',
      grossAnnualIncome
    );
  },
  setJointApplicantGrossAnnualIncomeConfirmedAtCurrentEmployment ({ commit }, grossAnnualIncomeConfirmed) {
    commit(
      'SET_JOINT_APPLICANT_GROSS_ANNUAL_INCOME_CONFIRMED_AT_CURRENT_EMPLOYMENT',
      grossAnnualIncomeConfirmed
    );
  },
  setJointApplicantGrossAnnualIncomeConfirmedValueAtCurrentEmployment ({ commit }, grossAnnualIncomeConfirmedValue) {
    commit(
      'SET_JOINT_APPLICANT_GROSS_ANNUAL_INCOME_CONFIRMED_VALUE_AT_CURRENT_EMPLOYMENT',
      grossAnnualIncomeConfirmedValue
    );
  },
  // Additional Income
  setJointApplicantHasAdditionalIncome ({ commit }, hasAdditionalIncome) {
    commit('SET_JOINT_APPLICANT_HAS_ADDITIONAL_INCOME', hasAdditionalIncome);
  },
  setJointApplicantAdditionalIncomeAmount ({ commit }, amount) {
    commit('SET_JOINT_APPLICANT_ADDITIONAL_INCOME_AMOUNT', amount);
  },
  setJointApplicantAdditionalIncomeFrequency ({ commit }, frequency) {
    commit('SET_JOINT_APPLICANT_ADDITIONAL_INCOME_FREQUENCY', frequency);
  },
  setJointApplicantAdditionalIncomeSource ({ commit }, source) {
    commit('SET_JOINT_APPLICANT_ADDITIONAL_INCOME_SOURCE', source);
  },

  // joint applicant previous employment
  setJointApplicantPreviousEmploymentType ({ commit }, type) {
    commit('SET_JOINT_APPLICANT_PREVIOUS_EMPLOYMENT_TYPE', type);
  },
  setJointApplicantPreviousEmploymentEmployerName ({ commit }, employerName) {
    commit('SET_JOINT_APPLICANT_PREVIOUS_EMPLOYMENT_EMPLOYER_NAME', employerName);
  },
  setJointApplicantPreviousEmploymentJobTitle ({ commit }, jobTitle) {
    commit('SET_JOINT_APPLICANT_PREVIOUS_EMPLOYMENT_JOB_TITLE', jobTitle);
  },
  setJointApplicantNumberOfMonthsAtPreviousEmployment ({ commit }, numberOfMonthsEmployed) {
    commit(
      'SET_JOINT_APPLICANT_NUMBER_OF_MONTHS_AT_PREVIOUS_EMPLOYMENT',
      numberOfMonthsEmployed
    );
  },
  setJointApplicantNumberOfYearsAtPreviousEmployment ({ commit }, numberOfYearsEmployed) {
    commit(
      'SET_JOINT_APPLICANT_NUMBER_OF_YEARS_AT_PREVIOUS_EMPLOYMENT',
      numberOfYearsEmployed
    );
  }
};
