import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import { getNumberFormatter } from '../../../utilities/getNumberFormatter';
import {
  MILITARY,
  SELF_EMPLOYED,
  YEARLY
} from '../../../values/application';
import { unemployedTypes } from '../../../values/employment';
import {
  calculateTotalNumberOfMonths
} from '../../../utilities/calculateTotalNumberOfMonths';
import { convertIncomeToMonthly } from '../../../utilities/convertIncomeToMonthly';

export const GROSS_ANNUAL_INCOME_LOWER_LIMIT = 24000;
export const GROSS_ANNUAL_INCOME_UPPER_LIMIT = 200000;

const currencyFormatter = getNumberFormatter();

const isGrossAnnualIncomeConfirmationRequired = (grossAnnualIncome = 0) =>
  isNumber(grossAnnualIncome) &&
  grossAnnualIncome > 0 &&
  (
    grossAnnualIncome <= GROSS_ANNUAL_INCOME_LOWER_LIMIT ||
    grossAnnualIncome > GROSS_ANNUAL_INCOME_UPPER_LIMIT
  );

export const getters = {
  // primary applicant current employment
  primaryApplicantCurrentEmploymentIsMilitary: (
    state,
    {
      primaryApplicantCurrentEmploymentTypeValue
    }
  ) => primaryApplicantCurrentEmploymentTypeValue === MILITARY,
  primaryApplicantCurrentEmploymentJobTitle: (
    state,
    {
      primaryApplicantCurrentEmploymentIsUnemployedType,
      primaryApplicantCurrentEmploymentTypeValue
    }
  ) => primaryApplicantCurrentEmploymentIsUnemployedType
    ? primaryApplicantCurrentEmploymentTypeValue
    : state.primary.current.jobTitle,
  primaryApplicantCurrentEmploymentEmployerName: (
    state,
    {
      primaryApplicantCurrentEmploymentIsUnemployedType,
      primaryApplicantCurrentEmploymentTypeValue
    }
  ) => primaryApplicantCurrentEmploymentIsUnemployedType
    ? primaryApplicantCurrentEmploymentTypeValue
    : state.primary.current.employerName,
  primaryApplicantHasCurrentEmploymentType: state =>
    state.primary.current.type !== null,
  primaryApplicantCurrentEmploymentTypeValue: state =>
    get(state, 'primary.current.type.value', null),
  primaryApplicantCurrentEmploymentTypeLabel: state =>
    get(state, 'primary.current.type.label', null),
  primaryApplicantCurrentEmploymentIsUnemployedType:
    (state, { primaryApplicantCurrentEmploymentTypeValue }) =>
      unemployedTypes.includes(primaryApplicantCurrentEmploymentTypeValue),
  primaryApplicantCurrentEmploymentIsSelfEmployed:
    (state, { primaryApplicantCurrentEmploymentTypeValue }) =>
      primaryApplicantCurrentEmploymentTypeValue === SELF_EMPLOYED,
  primaryApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber: state =>
    state.primary.current.grossAnnualIncome,
  primaryApplicantGrossAnnualIncomeAtCurrentEmploymentAsString: state =>
    currencyFormatter(state.primary.current.grossAnnualIncome),
  primaryApplicantGrossAnnualIncomeConfirmedAtCurrentEmployment: state =>
    state.primary.current.grossIncomeConfirmed,
  primaryApplicantGrossAnnualIncomeConfirmedValueAtCurrentEmployment: state =>
    state.primary.current.grossIncomeConfirmedValue,
  primaryApplicantGrossAnnualIncomeMatchesConfirmedValue: (
    state,
    {
      primaryApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber,
      primaryApplicantGrossAnnualIncomeConfirmedValueAtCurrentEmployment
    }
  ) => primaryApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber ===
    primaryApplicantGrossAnnualIncomeConfirmedValueAtCurrentEmployment,
  primaryApplicantGrossAnnualIncomeConfirmationRequiredAtCurrentEmployment: (
    state,
    {
      primaryApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber
    }
  ) =>
    isGrossAnnualIncomeConfirmationRequired(
      primaryApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber
    ),
  primaryApplicantMonthlyIncomeAtCurrentEmploymentAsNumber:
    (state, { primaryApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber }) =>
      convertIncomeToMonthly({
        frequency: YEARLY,
        value: primaryApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber
      }),
  primaryApplicantNumberOfYearsAtCurrentEmployment: state =>
    state.primary.current.numberOfYearsEmployed,
  primaryApplicantNumberOfMonthsAtCurrentEmployment: state =>
    state.primary.current.numberOfMonthsEmployed,
  primaryApplicantTotalNumberOfMonthsAtCurrentEmployment: state =>
    calculateTotalNumberOfMonths({
      months: state.primary.current.numberOfMonthsEmployed,
      years: state.primary.current.numberOfYearsEmployed
    }),
  primaryApplicantHasWorkedAtCurrentEmploymentLongerThanOneYear: (
    state,
    {
      primaryApplicantTotalNumberOfMonthsAtCurrentEmployment
    }
  ) =>
    primaryApplicantTotalNumberOfMonthsAtCurrentEmployment >= 12,
  primaryApplicantHasWorkedAtCurrentEmploymentLessThanOneYear: (
    state,
    {
      primaryApplicantTotalNumberOfMonthsAtCurrentEmployment
    }
  ) =>
    primaryApplicantTotalNumberOfMonthsAtCurrentEmployment < 12,
  // Additional Income Info
  primaryApplicantHasAdditionalIncome: state =>
    state.primary.additionalIncome.hasAdditionalIncome,
  primaryApplicantAdditionalIncomeAmountAsString: state =>
    currencyFormatter(state.primary.additionalIncome.amount),
  primaryApplicantAdditionalIncomeAmountAsNumber: state =>
    state.primary.additionalIncome.amount,
  primaryApplicantAdditionalIncomeFrequencyLabel: state =>
    get(state, 'primary.additionalIncome.frequency.label', null),
  primaryApplicantAdditionalIncomeFrequencyValue: state =>
    get(state, 'primary.additionalIncome.frequency.value', null),
  primaryApplicantAdditionalIncomeSource: state =>
    state.primary.additionalIncome.source,
  primaryApplicantAdditionalIncomeAmountPerMonthAsNumber:
    (
      state,
      {
        primaryApplicantAdditionalIncomeFrequencyValue,
        primaryApplicantAdditionalIncomeAmountAsNumber
      }
    ) => convertIncomeToMonthly(
      {
        frequency: primaryApplicantAdditionalIncomeFrequencyValue,
        value: primaryApplicantAdditionalIncomeAmountAsNumber
      }
    ),

  // primary applicant previous employment
  primaryApplicantPreviousEmploymentJobTitle: (
    state,
    {
      primaryApplicantPreviousEmploymentIsUnemployedType,
      primaryApplicantPreviousEmploymentTypeValue
    }
  ) => primaryApplicantPreviousEmploymentIsUnemployedType
    ? primaryApplicantPreviousEmploymentTypeValue
    : state.primary.previous.jobTitle,
  primaryApplicantPreviousEmploymentEmployerName: (
    state,
    {
      primaryApplicantPreviousEmploymentIsUnemployedType,
      primaryApplicantPreviousEmploymentTypeValue
    }
  ) => primaryApplicantPreviousEmploymentIsUnemployedType
    ? primaryApplicantPreviousEmploymentTypeValue
    : state.primary.previous.employerName,
  primaryApplicantHasPreviousEmploymentType: state =>
    state.primary.previous.type !== null,
  primaryApplicantPreviousEmploymentTypeValue: state =>
    get(state, 'primary.previous.type.value', null),
  primaryApplicantPreviousEmploymentTypeLabel: state =>
    get(state, 'primary.previous.type.label', null),
  primaryApplicantPreviousEmploymentIsUnemployedType:
    (state, { primaryApplicantPreviousEmploymentTypeValue }) =>
      unemployedTypes.includes(primaryApplicantPreviousEmploymentTypeValue),
  primaryApplicantPreviousEmploymentIsSelfEmployed:
    (state, { primaryApplicantPreviousEmploymentTypeValue }) =>
      primaryApplicantPreviousEmploymentTypeValue === SELF_EMPLOYED,
  primaryApplicantNumberOfYearsAtPreviousEmployment: state =>
    state.primary.previous.numberOfYearsEmployed,
  primaryApplicantNumberOfMonthsAtPreviousEmployment: state =>
    state.primary.previous.numberOfMonthsEmployed,
  primaryApplicantTotalNumberOfMonthsAtPreviousEmployment: state =>
    calculateTotalNumberOfMonths({
      months: state.primary.previous.numberOfMonthsEmployed,
      years: state.primary.previous.numberOfYearsEmployed
    }),

  // joint applicant current employment
  jointApplicantCurrentEmploymentJobTitle: (
    state,
    {
      jointApplicantCurrentEmploymentIsUnemployedType,
      jointApplicantCurrentEmploymentTypeValue
    }
  ) => jointApplicantCurrentEmploymentIsUnemployedType
    ? jointApplicantCurrentEmploymentTypeValue
    : state.joint.current.jobTitle,
  jointApplicantCurrentEmploymentEmployerName: (
    state,
    {
      jointApplicantCurrentEmploymentIsUnemployedType,
      jointApplicantCurrentEmploymentTypeValue
    }
  ) => jointApplicantCurrentEmploymentIsUnemployedType
    ? jointApplicantCurrentEmploymentTypeValue
    : state.joint.current.employerName,
  jointApplicantHasCurrentEmploymentType: state =>
    state.joint.current.type !== null,
  jointApplicantCurrentEmploymentIsMilitary: (
    state, { jointApplicantCurrentEmploymentTypeValue }
  ) => jointApplicantCurrentEmploymentTypeValue === MILITARY,
  jointApplicantCurrentEmploymentTypeValue: state =>
    get(state, 'joint.current.type.value', null),
  jointApplicantCurrentEmploymentTypeLabel: state =>
    get(state, 'joint.current.type.label', null),
  jointApplicantCurrentEmploymentIsUnemployedType:
    (state, { jointApplicantCurrentEmploymentTypeValue }) =>
      unemployedTypes.includes(jointApplicantCurrentEmploymentTypeValue),
  jointApplicantCurrentEmploymentIsSelfEmployed:
    (state, { jointApplicantCurrentEmploymentTypeValue }) =>
      jointApplicantCurrentEmploymentTypeValue === SELF_EMPLOYED,
  jointApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber: state =>
    state.joint.current.grossAnnualIncome,
  jointApplicantGrossAnnualIncomeAtCurrentEmploymentAsString: state =>
    currencyFormatter(state.joint.current.grossAnnualIncome),
  jointApplicantGrossAnnualIncomeConfirmedAtCurrentEmployment: state =>
    state.joint.current.grossIncomeConfirmed,
  jointApplicantGrossAnnualIncomeConfirmedValueAtCurrentEmployment: state =>
    state.joint.current.grossIncomeConfirmedValue,
  jointApplicantGrossAnnualIncomeMatchesConfirmedValue: (
    state,
    {
      jointApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber,
      jointApplicantGrossAnnualIncomeConfirmedValueAtCurrentEmployment
    }
  ) => jointApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber ===
    jointApplicantGrossAnnualIncomeConfirmedValueAtCurrentEmployment,
  jointApplicantGrossAnnualIncomeConfirmationRequiredAtCurrentEmployment: (
    state,
    {
      jointApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber
    }
  ) =>
    isGrossAnnualIncomeConfirmationRequired(
      jointApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber
    ),
  jointApplicantMonthlyIncomeAtCurrentEmploymentAsNumber:
    (state, { jointApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber }) =>
      convertIncomeToMonthly({
        frequency: YEARLY,
        value: jointApplicantGrossAnnualIncomeAtCurrentEmploymentAsNumber
      }),
  jointApplicantNumberOfYearsAtCurrentEmployment: state =>
    state.joint.current.numberOfYearsEmployed,
  jointApplicantNumberOfMonthsAtCurrentEmployment: state =>
    state.joint.current.numberOfMonthsEmployed,
  jointApplicantTotalNumberOfMonthsAtCurrentEmployment: state =>
    calculateTotalNumberOfMonths({
      months: state.joint.current.numberOfMonthsEmployed,
      years: state.joint.current.numberOfYearsEmployed
    }),
  jointApplicantHasWorkedAtCurrentEmploymentLongerThanOneYear: (
    state,
    {
      jointApplicantTotalNumberOfMonthsAtCurrentEmployment
    }
  ) =>
    jointApplicantTotalNumberOfMonthsAtCurrentEmployment >= 12,
  jointApplicantHasWorkedAtCurrentEmploymentLessThanOneYear: (
    state,
    {
      jointApplicantTotalNumberOfMonthsAtCurrentEmployment
    }
  ) =>
    jointApplicantTotalNumberOfMonthsAtCurrentEmployment < 12,
  // Additional Income Info
  jointApplicantHasAdditionalIncome: state =>
    state.joint.additionalIncome.hasAdditionalIncome,
  jointApplicantAdditionalIncomeAmountAsString: state =>
    currencyFormatter(state.joint.additionalIncome.amount),
  jointApplicantAdditionalIncomeAmountAsNumber: state =>
    state.joint.additionalIncome.amount,
  jointApplicantAdditionalIncomeFrequencyLabel: state =>
    get(state, 'joint.additionalIncome.frequency.label', null),
  jointApplicantAdditionalIncomeFrequencyValue: state =>
    get(state, 'joint.additionalIncome.frequency.value', null),
  jointApplicantAdditionalIncomeSource: state =>
    state.joint.additionalIncome.source,
  jointApplicantAdditionalIncomeAmountPerMonthAsNumber:
    (
      state,
      {
        jointApplicantAdditionalIncomeFrequencyValue,
        jointApplicantAdditionalIncomeAmountAsNumber
      }
    ) => convertIncomeToMonthly(
      {
        frequency: jointApplicantAdditionalIncomeFrequencyValue,
        value: jointApplicantAdditionalIncomeAmountAsNumber
      }
    ),

  // joint applicant previous employment
  jointApplicantPreviousEmploymentJobTitle: (
    state,
    {
      jointApplicantPreviousEmploymentIsUnemployedType,
      jointApplicantPreviousEmploymentTypeValue
    }
  ) => jointApplicantPreviousEmploymentIsUnemployedType
    ? jointApplicantPreviousEmploymentTypeValue
    : state.joint.previous.jobTitle,
  jointApplicantPreviousEmploymentEmployerName: (
    state,
    {
      jointApplicantPreviousEmploymentIsUnemployedType,
      jointApplicantPreviousEmploymentTypeValue
    }
  ) => jointApplicantPreviousEmploymentIsUnemployedType
    ? jointApplicantPreviousEmploymentTypeValue
    : state.joint.previous.employerName,
  jointApplicantHasPreviousEmploymentType: state =>
    state.joint.previous.type !== null,
  jointApplicantPreviousEmploymentTypeValue: state =>
    get(state, 'joint.previous.type.value', null),
  jointApplicantPreviousEmploymentTypeLabel: state =>
    get(state, 'joint.previous.type.label', null),
  jointApplicantPreviousEmploymentIsUnemployedType:
    (state, { jointApplicantPreviousEmploymentTypeValue }) =>
      unemployedTypes.includes(jointApplicantPreviousEmploymentTypeValue),
  jointApplicantPreviousEmploymentIsSelfEmployed:
    (state, { jointApplicantPreviousEmploymentTypeValue }) =>
      jointApplicantPreviousEmploymentTypeValue === SELF_EMPLOYED,
  jointApplicantNumberOfYearsAtPreviousEmployment: state =>
    state.joint.previous.numberOfYearsEmployed,
  jointApplicantNumberOfMonthsAtPreviousEmployment: state =>
    state.joint.previous.numberOfMonthsEmployed,
  jointApplicantTotalNumberOfMonthsAtPreviousEmployment: state =>
    calculateTotalNumberOfMonths({
      months: state.joint.previous.numberOfMonthsEmployed,
      years: state.joint.previous.numberOfYearsEmployed
    })
};
