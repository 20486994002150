import isEmpty from 'lodash/isEmpty';
import { getLeadChannel } from '../../../utilities/getLeadChannel';
import { EXPERIAN } from '../../../values/sponsor';
import { getSponsorIdFromUtmSource } from '../application';
import { AttributionLog, createAttribution } from './attributionLog';
import { CampaignAttribution, DirectMailAttribution } from './attributionTypes';

export class IngressService {
  /**
   *
   * @param {localStorage} localStorage - localstorage engine to store attributions
   * @param {number} defaultTtl - a global default ttl for attributions
   * @param {string} storageKey - the localstorage key used to store attribution data
   */
  constructor (localStorage, defaultTtl, storageKey) {
    this.localStorage = localStorage;
    this.defaultTtl = defaultTtl;
    this.storageKey = storageKey || 'attribution';

    this.attributionLog = new AttributionLog(
      this.localStorage.get(this.storageKey, {})
    );

    this.actualAttribution = null;
  }

  /**
   * Applies attribution to log and stores to vuex and local store
   * @param queryService
   * @param cookieService
   */
  attribute (queryService, cookieService) {
    this._doAttributions(queryService);
  }

  attributeDirectMail ({ lenderId, promoCode, sponsorClickId, referData }) {
    const attributedAt = new Date().toISOString();
    const directMailAttribution = new DirectMailAttribution({ sponsorClickId, attributedAt, lenderId, referData });

    this.attributionLog.pushUnique(
      directMailAttribution
    );

    this.localStorage.set(this.storageKey, this.attributionLog);
    this.actualAttribution = this.attributionLog.getCurrentAttribution();
  }

  /**
   * takes the attribution data and compares to localStorage data and stores and returns appropriate attribution

   * @param queryService
   * @private
   */
  _doAttributions (queryService) {
    const attributionData = queryService.getAttributionData();
    const autopayData = queryService.getAutopayData();
    const campaignData = queryService.getCampaignData(this.defaultTtl);
    const leadChannel = getLeadChannel()();
    const referData = queryService.getReferalData();

    const utmData = queryService.getUtms();
    const sponsorId = parseInt(attributionData.sponsorId || getSponsorIdFromUtmSource(utmData.source));

    // if no sponsor id could be determined we will not push any attribution to the log.
    if (sponsorId || referData || !isEmpty(autopayData)) {
      const attributionRecord = createAttribution({
        ...attributionData,
        ...campaignData,
        autopayData,
        leadChannel,
        referData,
        sponsorId
      });

      // set ttl to 30 days if we are an experian campaign
      if (attributionRecord instanceof CampaignAttribution && sponsorId === EXPERIAN) {
        attributionRecord.setTtl(30);
      }
      this.attributionLog.pushUnique(
        attributionRecord
      );
    }

    this.localStorage.set(this.storageKey, this.attributionLog);
    this.actualAttribution = this.attributionLog.getCurrentAttribution();
  }

  /**
   * reset localStorage
   */
  clearStorage () {
    this.localStorage.set(this.storageKey, new AttributionLog({}));
  }
}
