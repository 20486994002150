import * as contentful from 'contentful';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import { isNonEmptyString } from '../../../utilities/isNonEmptyString';
import {
  TRACKING_PIXEL_IFRAME,
  TRACKING_PIXEL_IMAGE,
  TRACKING_PIXEL_POSTBACK
} from '../../../utilities/trackingPixelTypes';
import {
  CREDIT_SNAP,
  CONSUMER_PORTAL,
  DEFAULT_APPLICATION_SUBMISSION_API,
  DEFAULT_EMAIL_ADDRESS,
  DEFAULT_FAVICON,
  DEFAULT_HEIGHT,
  DEFAULT_MAIL_CODE_SOURCE,
  DEFAULT_PHONE_NUMBER,
  DEFAULT_WIDTH,
  DEFAULT_WORKFLOW,
  RATE_GENIUS,
  DEFAULT_BRAND_NAME,
  META_DESCRIPTION,
  TRESL_BRAND_NAME
} from '../../../values/brand';

/**
 * @param {string|null} phoneNumber
 * @returns {string|null}
 */
const getPhoneNumberWithCountryCode = phoneNumber => {
  if (isNull(phoneNumber)) {
    return null;
  }

  return phoneNumber.startsWith('1-') ? phoneNumber : `1-${phoneNumber}`;
};

export const getters = {
  applicationSubmissionApi: state =>
    isNull(state.applicationSubmissionApi)
      ? DEFAULT_APPLICATION_SUBMISSION_API
      : state.applicationSubmissionApi,
  autopayFooterLinks: state => [
    {
      label: 'Terms Of Use',
      href: state.autopayTermsOfUseLink,
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'Privacy Policy',
      href: state.autopayPrivacyPolicyLink,
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'Do Not Sell My Personal Information',
      href: 'https://www.autopay.com/privacy-policy#rights',
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'California Collection Notice',
      href: 'https://www.autopay.com/privacy-policy#personal',
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'Legal',
      href: 'https://www.autopay.com/legal-disclaimer',
      rel: 'noreferrer',
      target: '_blank'
    }
  ],
  autopayWorkingHours: state => ({
    weekdays: 'Monday through Friday 7:00 am - 7:00 pm',
    weekends: 'Saturday 9:00 am - 5:00 pm Mountain Time'
  }),
  bearerToken: state => state.bearerToken,
  brand: state => state.brand || DEFAULT_BRAND_NAME,
  emailAddress: state =>
    isNull(state.emailAddress) ? DEFAULT_EMAIL_ADDRESS : state.emailAddress,
  faqLinks: (state, { isRateGeniusBrand, isTreslBrand }) =>
    isRateGeniusBrand
      ? state.rateGeniusFaqLink
      : isTreslBrand
        ? state.treslFaqLink
        : state.autopayFaqLink,
  favicon: state => (isNull(state.favicon) ? DEFAULT_FAVICON : state.favicon),
  footerLinks: (
    state,
    {
      isRateGeniusBrand,
      isTreslBrand,
      autopayFooterLinks,
      rateGeniusFooterLinks,
      treslFooterLinks
    }
  ) =>
    isRateGeniusBrand
      ? rateGeniusFooterLinks
      : isTreslBrand
        ? treslFooterLinks
        : autopayFooterLinks,
  footerLogoAlt: state => state.footerLogo.alt,
  footerLogoHeight: state =>
    isNull(state.footerLogo.height) ? DEFAULT_HEIGHT : state.footerLogo.height,
  footerLogoSrc: state => state.footerLogo.src,
  footerLogoWidth: state =>
    isNull(state.footerLogo.width) ? DEFAULT_WIDTH : state.footerLogo.width,
  footerWorkingHours: (
    state,
    {
      isTreslBrand,
      isRateGeniusBrand,
      autopayWorkingHours,
      rateGeniusWorkingHours,
      treslWorkingHours
    }
  ) =>
    isRateGeniusBrand
      ? rateGeniusWorkingHours
      : isTreslBrand
        ? treslWorkingHours
        : autopayWorkingHours,
  headerLogoAlt: state => state.headerLogo.alt,
  headerLogoHeight: state =>
    isNull(state.headerLogo.height) ? DEFAULT_HEIGHT : state.headerLogo.height,
  headerLogoSrc: state => state.headerLogo.src,
  headerLogoWidth: state =>
    isNull(state.headerLogo.width) ? DEFAULT_WIDTH : state.headerLogo.width,
  isAutopayBrand: state =>
    isNull(state.brand) || state.brand === DEFAULT_BRAND_NAME,
  isRateGeniusBrand: state =>
    isNonEmptyString(state.brand) && state.brand === RATE_GENIUS,
  isTreslBrand: state =>
    isNonEmptyString(state.brand) && state.brand === TRESL_BRAND_NAME,
  isIFrameTracking: state =>
    isNonEmptyString(state.trackingPixelUrl) &&
    state.trackingPixelType === TRACKING_PIXEL_IFRAME,
  isImageTracking: state =>
    isNonEmptyString(state.trackingPixelUrl) &&
    state.trackingPixelType === TRACKING_PIXEL_IMAGE,
  isPostbackTracking: state =>
    isNonEmptyString(state.trackingPixelUrl) &&
    state.trackingPixelType === TRACKING_PIXEL_POSTBACK,
  leadChannel: state => state.leadChannel,
  mailCodeSource: state =>
    isNonEmptyString(state.mailCodeSource)
      ? state.mailCodeSource
      : DEFAULT_MAIL_CODE_SOURCE,
  name: state => state.name,
  organizationCode: state => state.organizationCode,
  phoneNumber: state =>
    isNull(state.phoneNumber) ? DEFAULT_PHONE_NUMBER : state.phoneNumber,
  phoneNumberWithCountryCode: (state, { phoneNumber }) =>
    getPhoneNumberWithCountryCode(phoneNumber),
  privacyPolicyLink: (state, { isTreslBrand, isRateGeniusBrand }) =>
    isTreslBrand
      ? state.treslPrivacyPolicyLink
      : isRateGeniusBrand
        ? state.rateGeniusPrivacyPolicyLink
        : state.autopayPrivacyPolicyLink,
  rateGeniusFooterLinks: state => [
    {
      label: 'Terms Of Use',
      href: state.rateGeniusTermsOfUseLink,
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'Privacy Policy',
      href: state.rateGeniusPrivacyPolicyLink,
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'Do Not Sell My Personal Information',
      href: 'https://www.rategenius.com/privacy-policy/#rights',
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'California Collection Notice',
      href: 'https://www.rategenius.com/privacy-policy/#personal',
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'Legal',
      href: 'https://www.rategenius.com/legal/',
      rel: 'noreferrer',
      target: '_blank'
    }
  ],
  rateGeniusWorkingHours: state => ({
    weekdays: 'Monday through Friday 7:00 am - 7:00 pm',
    weekends: 'Saturday 9:00 am - 5:00 pm Central Time'
  }),
  shouldSubmitToCreditSnap: (state, { applicationSubmissionApi }) =>
    applicationSubmissionApi === CREDIT_SNAP,
  shouldSubmitToConsumerPortal: (state, { applicationSubmissionApi }) =>
    applicationSubmissionApi === CONSUMER_PORTAL,
  shouldSubmitToRateGenius: (state, { applicationSubmissionApi }) =>
    applicationSubmissionApi === RATE_GENIUS,
  stylesheet: state => state.stylesheet,
  termsOfUseLink: (state, { isTreslBrand, isRateGeniusBrand }) =>
    isTreslBrand
      ? state.treslTermsOfUseLink
      : isRateGeniusBrand
        ? state.rateGeniusTermsOfUseLink
        : state.autopayTermsOfUseLink,
  treslFooterLinks: state => [
    {
      label: 'Terms Of Use',
      href: state.treslTermsOfUseLink,
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'Privacy Policy',
      href: state.treslPrivacyPolicyLink,
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'Do Not Sell My Personal Information',
      href:
        'https://mytresl.com/privacy-policy/#Do-Not-Sell-My-Personal-Information',
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'California Collection Notice',
      href: 'https://mytresl.com/privacy-policy/#California-Residents',
      rel: 'noreferrer',
      target: '_blank'
    },
    {
      label: 'Legal',
      href: 'https://mytresl.com/legal-and-disclaimers/',
      rel: 'noreferrer',
      target: '_blank'
    }
  ],
  treslWorkingHours: state => ({
    weekdays: 'Monday through Friday 8:00 am - 8:00 pm',
    weekends: 'Saturday 9:00 am - 5:00 pm Central Time'
  }),
  trackingPixelType: state => state.trackingPixelType,
  trackingPixelUrl: (state, getters, rootState, rootGetters) => {
    const sponsorClickId = rootGetters['trackingData/sponsorClickId'] || rootGetters['trackingData/autopayData']?.partnerRecordUniqueIdentifier;
    if (state.trackingPixelUrl) {
      return state?.trackingPixelUrl
        .split('SPONSOR_CLICK_ID')
        .join(sponsorClickId)
        .split('IS_PRODUCTION')
        .join(encodeURIComponent(process.env.APP_ENV === 'prod'));
    }
  },
  workflow: state =>
    isNull(state.workflow) ? DEFAULT_WORKFLOW : state.workflow,

  client: () => {
    return contentful.createClient({
      accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
      environment: process.env.CONTENTFUL_ENVIRONMENT_ID,
      space: process.env.CONTENTFUL_SPACE_ID
    });
  },

  metaTags: (state, getters, rootState, rootGetters) => {
    const leadChannel = rootGetters.isRateGenius
      ? RATE_GENIUS
      : DEFAULT_BRAND_NAME;
    const description = META_DESCRIPTION.replace(
      '$leadChannelName',
      leadChannel
    );

    return {
      meta: [
        { name: 'author', content: leadChannel },
        { name: 'description', content: description }
      ]
    };
  },

  documentTitle: (state, { brand, name }) => {
    if (isEmpty(name) || isEmpty(brand)) {
      return DEFAULT_BRAND_NAME;
    }

    if (brand.toLowerCase() === name.toLowerCase()) {
      return brand;
    }

    return `${brand} | ${name}`;
  },

  headLine: (state, { brand, name }) => {
    if (isEmpty(name) || isEmpty(brand)) {
      return DEFAULT_BRAND_NAME;
    }

    if (brand.toLowerCase() === name.toLowerCase()) {
      return `${brand} is working`;
    }

    return `${brand} and ${name} are working together`;
  }
};
