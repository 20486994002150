
export class PromoCode {
  constructor (nxgHttpRequest) {
    this.nxgHttpRequest = nxgHttpRequest;
  }

  async lookup (promoCode, zipCode) {
    const response = await this.nxgHttpRequest.get(`/api/v2/promo_codes/${promoCode}`, { query: { zip_code: zipCode } });
    return response.data.data;
  }
}
